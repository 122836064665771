import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import "dayjs/locale/pt-br";

import Loading from "../../Components/LoadingComponent";

import { ApplicationState } from "../../store";

function Links({}: any) {

  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
 
  return (
    <div className="pageBox">
      <Loading loading={false} />
      <div className="relatorio">
        <Typography variant="h6" component="h6">
          Links
        </Typography>
        <Divider />
        <Grid
          sx={{ flexGrow: 1, marginTop: "1em", marginBottom: "1em" }}
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid item xs={8}>
            <b>Destaques:</b> https://app-conectado.connectapps.com.br/carrossel/RGVzdGFxdWVz
            <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/RGVzdGFxdWVz").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
          </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Segurança do Trabalho:</b> https://app-conectado.connectapps.com.br/carrossel/U2VndXJhbmNhTm9UcmFiYWxob3M=
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/U2VndXJhbmNhTm9UcmFiYWxob3M=").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Comunicados:</b> https://app-conectado.connectapps.com.br/carrossel/Q29tdW5pY2Fkb3M=
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/Q29tdW5pY2Fkb3M=").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Treinamentos de 1 Ponto:</b> https://app-conectado.connectapps.com.br/carrossel/VHJlaW5hbWVudG9zIGRlIDEgUG9udG8=
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/VHJlaW5hbWVudG9zIGRlIDEgUG9udG8=").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Cursos:</b> https://app-conectado.connectapps.com.br/carrossel/Q3Vyc29z
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/Q3Vyc29z").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Trilhas:</b> https://app-conectado.connectapps.com.br/carrossel/VHJpbGhhcw==
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/VHJpbGhhcw==").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Multimídia:</b> https://app-conectado.connectapps.com.br/carrossel/TXVsdGltaWRpYQ==
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/TXVsdGltaWRpYQ==").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Documentos:</b> https://app-conectado.connectapps.com.br/carrossel/RG9jdW1lbnRvcw==
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/RG9jdW1lbnRvcw==").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
          <b>Games:</b> https://app-conectado.connectapps.com.br/carrossel/R2FtZXM=
          <Tooltip title={copiado}>
            <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText("https://app-conectado.connectapps.com.br/carrossel/R2FtZXM=").then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                <Icon >content_copy</Icon>
            </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

          
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {},
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Links);
