import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import { doLoginApp, loginRequest } from "../../store/ducks/auth/actions";

import "./style.css";
import { ApplicationState } from "../../store";
import { Auth } from "../../store/ducks/auth/types";
import Loading from "../../Components/LoadingComponent";

const LoginPage = ({ loginRequest, auth, doLoginApp }: any) => {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");

  useEffect(() => {
    if (auth.error) {
      if (
        auth.errorMessage &&
        auth.errorMessage.response &&
        auth.errorMessage.response.data == "Bad credentials"
      ) {
        alert(
          "Atenção\nNão foi possivel realizar login, verifique suas credenciais e tente novamente ou procure um Administrador!"
        );
      } else if (
        auth.errorMessage &&
        auth.errorMessage.response &&
        auth.errorMessage.response.data == "User account has expired"
      ) {
        alert(
          "Atenção\nNão foi possivel realizar login, sua conta foi expirada procure um Administrador!"
        );
      } else if (
        auth.errorMessage &&
        auth.errorMessage.response &&
        auth.errorMessage.response.data == "User is disabled"
      ) {
        alert(
          "Atenção\nNão foi possivel realizar login, sua conta foi inativada procure um Administrador!"
        );
      } else if (auth.errorMessage) {
        alert(
          "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
            auth.errorMessage.message
        );
      }
    }
  }, [auth.error]);

  useEffect(() => {
    localStorage.setItem("idUsuario", auth.data.idUsuario);
    if (!auth.error && auth.data != null) {
      trataLogin(auth.data);
    }
  }, [auth.data.idUsuario]);

  const trataLogin = (loginDefs: Auth) => {
    if (loginDefs.isExpirado) {
      alert(
        "Atenção\nNão foi possivel realizar login, seu usuário esta inativado. Procure um Administrador!"
      );
    } else if (loginDefs.idPerfil) {
      const idsPerfilPermitidos = [1, 88, 47, 90, 45, 48, 49, 29, 30];
      if (!idsPerfilPermitidos.includes(loginDefs.idPerfil)) {
        alert(
          "Atenção\nVocê não tem permissão para acessar o portal Administrativo, acesse por meio do aplicativo no seu celular ou procure um Administrador!"
        );
      } else {
        doLoginApp();
      }
    }
  };

  const doLogin = (e: any) => {
    e.preventDefault();

    let body = {
      login,
      senha,
    };
    loginRequest(body);
  };

  return (
    <div className="container">
      <Loading loading={auth.loading} />
      <div className="loginPage">
        <div className="side">
          <div className="imgBox">
            <img
              style={{ width: "100%" }}
              src={require("../../assets/img/logo.png")}
            />
          </div>

          <div className="formBox">
            <form className="form">
              <input
                className="input"
                type="text"
                name="login"
                value={login}
                onChange={(event) => setLogin(event.target.value)}
                placeholder="Login"
                required={true}
              />
              <input
                className="input"
                type="password"
                name="senha"
                value={senha}
                onChange={(event) => setSenha(event.target.value)}
                placeholder="Senha"
                required={true}
              />
              <button className="button" onClick={doLogin}>
                Entrar
              </button>
            </form>
          </div>

          <footer className="footerBox">
            <div className="imgBox">
              <img
                style={{ width: 224, height: 33 }}
                src={require("../../assets/img/logo_footer.png")}
              />
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doLoginApp, loginRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
