import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as SegurancaNoTrabalhosActions from '../../store/ducks/segurancaNoTrabalho/actions'
import SegurancaNoTrabalhoTableComponent from "../../Components/TableSegurancaNoTrabalho";
import ModalCadastroSegurancaNoTrabalho from "../../Components/ModalCadastroSegurancaNoTrabalho";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmSegurancaNoTrabalho",
    txtBusca: ""
};

function SegurancaNoTrabalho({
    segurancaNoTrabalho,
    utils,
    getSegurancaNoTrabalhoTable,
    onInativarSegurancaNoTrabalho,
    getSegurancaNoTrabalhoData,
    onSaveSegurancaNoTrabalho,
    onUpdateSegurancaNoTrabalho,
    onNotificacaoSegurancaNoTrabalho,
    clearSegurancaNoTrabalhoData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(segurancaNoTrabalho.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + segurancaNoTrabalho.errorMessage);
        }
    }, [segurancaNoTrabalho.error]);

    useEffect(() => {
        if(!segurancaNoTrabalho.loadingPersis) {
            handleCloseAndReload()
        }
    }, [segurancaNoTrabalho.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getSegurancaNoTrabalhoTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(segurancaNoTrabalho.data.table != null){
            setTable(segurancaNoTrabalho.data.table);
        }

    }, [segurancaNoTrabalho.data]);

    useEffect(() => {
        if(!segurancaNoTrabalho.loadingInativacao){
            getSegurancaNoTrabalhoTable(body);
        }
    }, [segurancaNoTrabalho.loadingInativacao]);

    const getSegurancaNoTrabalhoTableData = () => {
        getSegurancaNoTrabalhoTable(body);
    }

    const handleCadastro = () => {
        clearSegurancaNoTrabalhoData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length === 0) {
            alert('Selecione uma segurancaNoTrabalho que gostaria de alterar');

        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas uma segurancaNoTrabalho para alteração!');

        } else {
            getSegurancaNoTrabalhoData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length === 0) {
            alert('Selecione as Segurança do Trabalho que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.SEGURANCA_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarSegurancaNoTrabalho(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistSegurancaNoTrabalho = (edicao: boolean, segurancaNoTrabalho: FormData) => {
        if(edicao) {
            setIdSelecionado([]);
            onUpdateSegurancaNoTrabalho(segurancaNoTrabalho);
        } else {
            onSaveSegurancaNoTrabalho(segurancaNoTrabalho);
        }

    }

    const handleCloseAndReload = () => {
        clearSegurancaNoTrabalhoData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={segurancaNoTrabalho.loading || segurancaNoTrabalho.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroSegurancaNoTrabalho 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                segurancaNoTrabalhoData={segurancaNoTrabalho.data.segurancaNoTrabalho} 
                id={idsSelecionado[0]}
                persist={handlePersistSegurancaNoTrabalho}
                enviaNotificacao={(idSegurancaNoTrabalho: any) =>  onNotificacaoSegurancaNoTrabalho(idSegurancaNoTrabalho)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <SegurancaNoTrabalhoTableComponent
                    loading={segurancaNoTrabalho.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    segurancaNoTrabalho: state.segurancaNoTrabalho,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...SegurancaNoTrabalhosActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SegurancaNoTrabalho);
