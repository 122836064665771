import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getUsuarioTableFailure,
  getUsuarioTableSuccess,
  onAtivarUsuarioFailure,
  onAtivarUsuarioSuccess,
  onInativarUsuarioFailure,
  onInativarUsuarioSuccess,
  onImportarUsuarioFailure,
  onImportarUsuarioSuccess,
  onImportarUsuarioT5Failure,
  onImportarUsuarioT5Success,
  onInativarMassaFailure,
  onInativarMassaSuccess,
  onResetSenhaFailure,
  onResetSenhaSuccess,
  onResetSenhaMassaFailure,
  onResetSenhaMassaSuccess,
  getUsuarioDataFailure,
  getUsuarioDataSuccess,
  onSaveUsuarioFailure,
  onSaveUsuarioSuccess,
  onUpdateUsuarioFailure,
  onUpdateUsuarioSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getUsuarioTable(data: any): any {
  const body = data.payload;

  const doRequest = async () => {
    const token = localStorage.getItem("accessToken");
    return api
      .post("usuario/listAllUsuarioTable", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getUsuarioTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getUsuarioTableFailure(error.message));
  }
}

function* onInativarUsuario(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/desativar", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onInativarUsuarioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarUsuarioFailure(error.message));
  }
}

function* onAtivarUsuario(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/ativar", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onAtivarUsuarioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onAtivarUsuarioFailure(error.message));
  }
}

function* onResetSenha(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/resetSenhaUsuario", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onResetSenhaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onResetSenhaFailure(error.message));
  }
}

function* onResetSenhaMassa(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/resetSenhasMassa", body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then(async (res) => {
        const blobTxt = await new Blob([res.data]).text();
        if (
          blobTxt == "Todos os usuários solicitados tiveram a senha resetada!"
        ) {
          alert(blobTxt);
          return;
        }
        alert(
          "Atenção\no Reset em massa contem erros! Por Favor Verifique os dados no arquivo que foi retornado!"
        );
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  try {
    yield put(onResetSenhaMassaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onResetSenhaMassaFailure(error.message));
  }
}

function* onImportarUsuario(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");
  const idUsuario = localStorage.getItem("idUsuario");

  const doRequest = () => {
    return api
      .post("usuario/importarUsuariosMassa?idUsuario=" + idUsuario, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then(async (res) => {
        const blobTxt = await new Blob([res.data]).text();
        if (blobTxt == "Todos os usuarios foram importados sem erros!") {
          alert(blobTxt);
          return;
        }
        alert(
          "Atenção\nA importação contem erros! Por Favor Verifique os dados no arquivo que foi retornado!"
        );
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  try {
    yield put(onImportarUsuarioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onImportarUsuarioFailure(error.message));
  }
}

function* onImportarUsuarioT5(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("avaliacao/importarUsuariosAvaliacao", body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        timeout: 1200000
      })
      .then(async (res) => {
        const blobTxt = await new Blob([res.data]).text();
        if (blobTxt == "Todos os usuarios foram importados sem erro!") {
          alert(blobTxt);
          return;
        }
        alert(
          "Atenção\nA importação contem erros! Por Favor Verifique os dados no arquivo que foi retornado!"
        );
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  try {
    yield put(onImportarUsuarioT5Success(yield call(doRequest)));
  } catch (error: any) {
    yield put(onImportarUsuarioT5Failure(error.message));
  }
}

function* onInativarMassa(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/inativarUsuariosMassa", body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then(async (res) => {
        const blobTxt = await new Blob([res.data]).text();
        if (blobTxt == "Todos os usuarios foram inativados!") {
          alert(blobTxt);
          return;
        }
        alert(
          "Atenção\nA inativação contem erros! Por Favor Verifique os dados no arquivo que foi retornado!"
        );
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  try {
    yield put(onInativarMassaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarMassaFailure(error.message));
  }
}

function* getUsuarioData(data: any): any {
  const idUsuario = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`usuario/findById?idUsuario=${idUsuario}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getUsuarioDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getUsuarioDataFailure(error.message));
  }
}

function* onSaveUsuario(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .put("usuario/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onSaveUsuarioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveUsuarioFailure(error));
  }
}

function* onUpdateUsuario(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("usuario/update", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };

  try {
    yield put(onUpdateUsuarioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onUpdateUsuarioFailure(error.message));
  }
}

export {
  getUsuarioTable,
  onInativarUsuario,
  onAtivarUsuario,
  onResetSenha,
  onResetSenhaMassa,
  onImportarUsuario,
  onImportarUsuarioT5,
  onInativarMassa,
  getUsuarioData,
  onSaveUsuario,
  onUpdateUsuario,
};
