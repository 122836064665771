import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as ComunicadoActions from '../../store/ducks/comunicado/actions'
import ComunicadoTableComponent from "../../Components/TableComunicado";
import ModalCadastroComunicado from "../../Components/ModalCadastroComunicado";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmCursoComunicado",
    txtBusca: ""
};

function Comunicado({
    comunicado,
    utils,
    getComunicadoTable,
    onInativarComunicado,
    getComunicadoData,
    onSaveComunicado,
    onUpdateComunicado,
    onNotificacaoComunicado,
    clearComunicadoData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(comunicado.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + comunicado.errorMessage);
        }
    }, [comunicado.error]);

    useEffect(() => {
        if(!comunicado.loadingPersis) {
            handleCloseAndReload()
        }
    }, [comunicado.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getComunicadoTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(comunicado.data.table != null){
            setTable(comunicado.data.table);
        }

    }, [comunicado.data]);

    useEffect(() => {
        if(!comunicado.loadingInativacao){
            getComunicadoTable(body);
        }
    }, [comunicado.loadingInativacao]);

    const getComunicadoTableData = () => {
        getComunicadoTable(body);
    }

    const handleCadastro = () => {
        clearComunicadoData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um comunicado que gostaria de alterar');

        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um comunicado para alteração!');

        } else {
            getComunicadoData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os comunicados que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.COMUNICADO_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarComunicado(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistComunicado = (edicao: boolean, comunicado: FormData) => {
        if(edicao) {
            setIdSelecionado([]);
            onUpdateComunicado(comunicado);
        } else {
            onSaveComunicado(comunicado);
        }
    }

    const handleCloseAndReload = () => {
        clearComunicadoData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={comunicado.loading || comunicado.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroComunicado 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                comunicadoData={comunicado.data.comunicado} 
                persist={handlePersistComunicado}
                id={idsSelecionado[0]}
                enviaNotificacao={(idCursoComunicado: any) => onNotificacaoComunicado(idCursoComunicado)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <ComunicadoTableComponent
                    loading={comunicado.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    comunicado: state.comunicado,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...ComunicadoActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Comunicado);
